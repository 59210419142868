import { init, configureScope } from '@sentry/react';
import { SDK_VERSION } from '@sentry/core';
import {
  BrowserTracing,
  defaultRequestInstrumentationOptions,
} from '@sentry/tracing';
import Router from 'next/router';
import { fill, getGlobalObject, stripUrlQueryAndFragment } from '@sentry/utils';

/**
 * due to requiring the `serverless` target in next.config.js and
 * `@serverless/cli` not supporting Next.js 12 where said target is deprecated,
 * we have to do some manual work which would otherwise would have been
 * orchestrated by `withSentryConfig`
 */

// https://github.com/getsentry/sentry-javascript/blob/master/packages/nextjs/src/config/webpack.ts#L144
global.__rewriteFramesDistDir = '.next';

// copied from https://github.com/getsentry/sentry-javascript/blob/master/packages/nextjs/src/performance/client.ts
const DEFAULT_TAGS = {
  'routing.instrumentation': 'next-router',
};

let activeTransaction = undefined;
let prevTransactionName = undefined;
let startTransaction = undefined;

function nextRouterInstrumentation(
  startTransactionCb,
  startTransactionOnPageLoad = true,
  startTransactionOnLocationChange = true
) {
  startTransaction = startTransactionCb;
  Router.ready(() => {
    if (startTransactionOnPageLoad) {
      const global = getGlobalObject();

      prevTransactionName =
        Router.route !== null
          ? stripUrlQueryAndFragment(Router.route)
          : global.location.pathname;
      activeTransaction = startTransactionCb({
        name: prevTransactionName,
        op: 'pageload',
        tags: DEFAULT_TAGS,
      });
    }

    if (!startTransactionOnLocationChange) return;

    const routerPrototype = Object.getPrototypeOf(Router.router);
    fill(routerPrototype, 'changeState', changeStateWrapper);
  });
}

function changeStateWrapper(originalChangeStateWrapper) {
  const wrapper = function (method, url, as, options, ...args) {
    const newTransactionName = stripUrlQueryAndFragment(url);
    // do not start a transaction if it's from the same page
    if (
      startTransaction !== undefined &&
      prevTransactionName !== newTransactionName
    ) {
      if (activeTransaction) {
        activeTransaction.finish();
      }
      const tags = {
        ...DEFAULT_TAGS,
        method,
        ...options,
      };
      if (prevTransactionName) {
        tags.from = prevTransactionName;
      }
      prevTransactionName = newTransactionName;
      activeTransaction = startTransaction({
        name: prevTransactionName,
        op: 'navigation',
        tags,
      });
    }
    return originalChangeStateWrapper.call(
      this,
      method,
      url,
      as,
      options,
      ...args
    );
  };

  return wrapper;
}

init({
  dsn: process.env.NEXT_PUBLIC_CMS_SENTRY_DSN, // injected in next.config.js
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV, // also injected
  release: process.env.NEXT_PUBLIC_RELEASE_SHA, // same
  enabled: process.env.NODE_ENV !== 'development',
  // copied from https://github.com/getsentry/sentry-javascript/blob/master/packages/nextjs/src/index.client.ts
  _metadata: {
    sdk: {
      name: 'sentry.javascript.react',
      packages: [
        {
          name: `npm:@sentry/react`,
          version: SDK_VERSION,
        },
      ],
      version: SDK_VERSION,
    },
  },
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        ...defaultRequestInstrumentationOptions.tracingOrigins,
        /^(api\/)/,
      ],
      routingInstrumentation: nextRouterInstrumentation,
    }),
  ],
});

configureScope((scope) => {
  scope.setTag('runtime', 'browser');
  scope.addEventProcessor((event) =>
    event.type === 'transaction' && event.transaction === '/404' ? null : event
  );
});
